<template>
  <div class="comment-list">
    <error :errors="articleError" />
    <b-overlay :show="formBusy" rounded="sm">
      <portlet title="ئەسەر تەپسىلاتى" head-class="border-0">
        <template v-slot:toolbar>
          <b-row class="align-items-center d-none d-sm-flex">
            <b-col >
              <datetime placeholder="باشلىنىش ۋاقتىنى تاللاڭ" id="start_time" v-model="start_time" input-class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
            </b-col>
            <b-col >
              <datetime placeholder="ئاياقلىشىش ۋاقتىنى تاللاڭ" id="end_time" v-model="end_time" input-class="form-control" :phrases="{ok: 'جەزىملەش', cancel: 'بىكار قىلىش'}" />
            </b-col>
            <b-col :md="start_time && end_time ? 3.5 : 2" :sm="start_time && end_time ? 3.5 : 2" >
              <button v-b-tooltip.hover @click="onClickRefresh"  title="يېڭىلاش" class="btn btn-clean btn-sm mr-2 ">
                يېڭىلاش
              </button>
              <button v-if="start_time && end_time" v-b-tooltip.hover @click="onClickClear"  title="تازلاش" class="btn btn-clean btn-sm ">
                تازلاش
              </button>
            </b-col>
          </b-row>
        </template>
        <template v-slot:body>
          <div class="kt-widget kt-widget--user-profile-3">
            <div class="kt-widget__top">
              <div class="kt-widget__media kt-hidden-">
                <b-img :src="article.cover_image_preview" alt="image" />
              </div>
              <div class="kt-widget__pic kt-widget__pic--danger kt-font-danger kt-font-boldest kt-font-light kt-hidden">
                JM
              </div>
              <div class="kt-widget__content">
                <div class="kt-widget__head">
                  <a class="kt-widget__username">
                    {{ article.title }}
                  </a>
                </div>
                <div class="kt-widget__subhead">
                  <a v-b-tooltip title="يازغۇچىلار" style="cursor: default" ><i class="fa fa-user-edit"></i> {{ article.authors && article.authors.map(item=> item.title).join(' / ') }} </a>
                  <a v-b-tooltip title="تۈرلەر" style="cursor: default" ><i class="fa fa-book-open"></i>{{ article.authors && article.categories.map(item=> item.title).join(' / ') }}</a>
                </div>
                <div class="kt-widget__info">
                  <div class="kt-widget__desc">
                    {{ article.description }}
                  </div>
                </div>
              </div>
            </div>
            <div class="kt-widget__bottom">
              <b-row style="width: 100%" class="row-no-padding">
                <b-col sm="6" md="3">
                  <div class="kt-widget__item">
                    <div class="kt-widget__icon">
                      <i class="flaticon-medical"></i>
                    </div>
                    <div class="kt-widget__details">
                      <span class="kt-widget__title">كۆرىلىشى</span>
                      <a class="kt-widget__value">{{ article.click_times }} قېتىم</a>
                    </div>
                  </div>
                </b-col>
                <b-col sm="6" md="3">
                  <div class="kt-widget__item">
                    <div class="kt-widget__icon">
                      <i class="flaticon-users"></i>
                    </div>
                    <div class="kt-widget__details">
                      <span class="kt-widget__title">ساقلىۋالغانلار</span>
                      <a class="kt-widget__value">{{ article.favoriters_count }} نەپەر</a>
                    </div>
                  </div>
                </b-col>
                <b-col sm="6" md="3">
                  <div class="kt-widget__item">
                    <div class="kt-widget__icon">
                      <i class="flaticon-list-2"></i>
                    </div>
                    <div class="kt-widget__details">
                      <span class="kt-widget__title">قىسىم سانى</span>
                      <a class="kt-widget__value ">{{ article.total_episodes }} قىسىم</a>
                    </div>
                  </div>
                </b-col>
                <b-col sm="6" md="3">
                  <div class="kt-widget__item">
                    <div class="kt-widget__icon">
                      <i class="flaticon-list-1"></i>
                    </div>
                    <div class="kt-widget__details">
                      <span class="kt-widget__title">پۈتكەن قىسىم سانى</span>
                      <a class="kt-widget__value">{{ article.episodes_count }} قىسىم</a>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </template>
      </portlet>
      <portlet body-fit>
        <template v-slot:body>
          <div class="row row-no-padding row-col-separator-xl">
            <div class="col-md-12 col-lg-12 col-xl-4">
              <!--begin:: Widgets/Stats2-1 -->
              <div class="kt-widget1">
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">ئۇمۇمىي سېتىلىشى</h3>
                    <span class="kt-widget1__desc">توپ پارچە سېتىلغان</span>
                  </div>
                  <span v-if="statics.total_order" class="kt-widget1__number kt-font-brand">{{statics.total_order}} دانە زاكاز</span>
                  <span v-else class="kt-widget1__number kt-font-brand">تېخى سېتىلمىغان</span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">توپ سېتىلغىنى</h3>
                    <span class="kt-widget1__desc">توپ باھادا سېتىلغىنى</span>
                  </div>
                  <span v-if="statics.article_order" class="kt-widget1__number kt-font-danger">{{statics.article_order}} دانە زاكاز</span>
                  <span v-else class="kt-widget1__number kt-font-danger">تېخى سېتىلمىغان</span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">پارچە سېتىلغىنى</h3>
                    <span class="kt-widget1__desc">قىسىم بويىچە سېتىلغىنى</span>
                  </div>
                  <span v-if="statics.episode_order" class="kt-widget1__number kt-font-success">{{statics.episode_order}} دانە زاكاز</span>
                  <span v-else class="kt-widget1__number kt-font-success">تېخى سېتىلمىغان</span>
                </div>
              </div>

              <!--end:: Widgets/Stats2-1 -->
            </div>
            <div class="col-md-12 col-lg-12 col-xl-4">

              <!--begin:: Widgets/Stats2-2 -->
              <div class="kt-widget1">
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">ئۇمۇمىي كىرىم</h3>
                    <span class="kt-widget1__desc">توپ پارچە سېتىلغان ئۇمۇمى كىرىم</span>
                  </div>
                  <span class="kt-widget1__number kt-font-success">{{statics.total_price}} يۈەن</span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">توپ كىرىم</h3>
                    <span class="kt-widget1__desc">توپ سېتىلغان كىرىم</span>
                  </div>
                  <span class="kt-widget1__number kt-font-info">{{statics.article_price}} يۈەن</span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">پارچە كىرىم</h3>
                    <span class="kt-widget1__desc">قىسىم بويىچە سېتىلغان كىرىم</span>
                  </div>
                  <span class="kt-widget1__number kt-font-warning">{{statics.episode_price}} يۈەن</span>
                </div>
              </div>

              <!--end:: Widgets/Stats2-2 -->
            </div>
            <div class="col-md-12 col-lg-12 col-xl-4">
              <!--begin:: Widgets/Stats2-3 -->
              <div class="kt-widget1">
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">ئاپتور كىرىمى</h3>
                    <span class="kt-widget1__desc">ئاپتور ئېلىشقا تىگىشلىك پۇل</span>
                  </div>
                  <span class="kt-widget1__number kt-font-success">{{statics.author_price}} يۈەن</span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">ئاپتور پايدا نىسپىتى</h3>
                    <span class="kt-widget1__desc">ئۇمۇمى پايدىدىن ئاپتورغا ئايرىلىدىغان نىسپەت</span>
                  </div>
                  <span class="kt-widget1__number kt-font-danger">{{article.author_profit_ratio}}%</span>
                </div>
                <div class="kt-widget1__item">
                  <div class="kt-widget1__info">
                    <h3 class="kt-widget1__title">سۇپا پايدىسى</h3>
                    <span class="kt-widget1__desc">ئاپتور پايدىسىدىن سىرت سۇپىغا قالغان پايدا، ۋاكالەتچى چىقىمى ھېسابلانمىدى</span>
                  </div>
                  <span class="kt-widget1__number kt-font-primary">{{statics.platform_price}} يۈەن</span>
                </div>
              </div>

              <!--end:: Widgets/Stats2-3 -->
            </div>
          </div>
        </template>
      </portlet>
      <b-row>
        <b-col cols="12">
          <portlet>
            <template v-slot:body>
              <div class="kt-pagination kt-pagination--brand">
                <div class="kt-pagination__links">
                  <b-pagination
                    class="mb-0"
                    v-if="ordersMeta.total"
                    v-model="ordersMeta.current_page"
                    :total-rows="ordersMeta.total"
                    :per-page="ordersMeta.per_page"
                    @change="onChangePage"
                  />
                </div>
                <div v-if="ordersMeta.total" class="kt-pagination__toolbar">
                  سېتىۋالغانلار تىزىملىكى
                </div>
                <div v-else class="kt-pagination__toolbar">
                  ھازىرچە سېتىۋالغانلار يوق ئىكەن
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-for="order in orders" md="3" :key="order.id" sm="6">
          <portlet title=" " fluidHeight head-class="kt-portlet__head--noborder">
            <template v-slot:body>
              <div class="kt-widget kt-widget--user-profile-2">
                <div class="kt-widget__head">
                  <div class="kt-widget__media">
                    <b-img class="kt-widget__img" :class="{'kt-hidden': ! order.orderer.avatar}" rounded="" height="50" :src="order.orderer.avatar" alt="image" />
                    <div :class="{'kt-hidden': order.orderer.avatar}" class="kt-widget__pic kt-widget__pic--success kt-font-success kt-font-boldest">
                      {{ order.orderer.nickname }}
                    </div>
                  </div>
                  <div class="kt-widget__info overflow-hidden">
                    <a class="kt-widget__username">
                      {{ order.orderer.nickname }}
                    </a>
                    <span class="kt-widget__desc">{{ order.order_type === 'article' ? 'توپ سېتىۋالغان' : 'پارچە سېتىۋالغان' }}</span>
                  </div>
                </div>
                <div class="kt-widget__body">
                  <div class="kt-widget__item">
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">{{ order.order_type === 'article' ? 'ئەسەر نامى' : 'سېتىۋالغان قىسمى' }}</span>
                      <span class="kt-widget__data">{{ order.orderable.title.substr(0, 40) }}</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">سوممىسى: </span>
                      <span class="kt-widget__data">{{ order.order_total_fee }}</span>
                    </div>
                    <div class="kt-widget__contact">
                      <span class="kt-widget__label">پۇل تۆلىگەن ۋاقتى: </span>
                      <span class="kt-widget__data">{{ order.paid_at }} </span>
                    </div>
                  </div>
                </div>
                <div v-if="false" class="kt-widget__footer">
                  <b-button type="button" variant="danger" class="btn-upper">پۇل قايتۇرۇش</b-button>
                </div>
              </div>
            </template>
          </portlet>
        </b-col>
      </b-row>
      <!--<portlet title="سېتىۋالغانلار تىزىملىكى">
        <template v-slot:body></template>
      </portlet>-->
    </b-overlay>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import Error from "../../../partials/widgets/Error";
  import Portlet from "../../../partials/content/Portlet";
  import { mapGetters } from "vuex";
  import articleMixin from "../../../../mixins/articleMixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import 'vue-datetime/dist/vue-datetime.css'
  import { Datetime } from "vue-datetime";
  export default {
    name: "index",
    mixins: [ articleMixin, formBusyMixin ],
    components: { Error, Portlet, Datetime },
    computed: {
      ...mapGetters(["article","articleError"]),
      aid(){
        let { id } = this.$route.params;
        return id;
      }
    },
    created() {
      this.findArticle(this.aid);
      this.getArticleStatics();
      this.getArticleOrderList();
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "ئەسەر باشقۇرۇش", route: "/articles/list" },
        { title: " ئەسەر تەپسىلاتى" },
      ]);
    },
    data(){
      return {
        start_time: '',
        end_time: '',
        statics: {},
        orders: [],
        ordersMeta: {},
        formBusy: false,
      };
    },
    methods: {
      onClickRefresh(){
        let { start_time, end_time } = this;
        if ( ! start_time || ! end_time ){
          this.start_time = '';
          this.end_time = '';
        }
        this.getArticleStatics({start_time, end_time});
        this.getArticleOrderList(1, {start_time, end_time});
      },
      onClickClear(){
        this.start_time = '';
        this.end_time = '';
        this.getArticleStatics();
        this.getArticleOrderList();
      },
      getArticleStatics(params = {}){
        this.articleStatics(this.aid, params)
                .then(response=>{
                  let { data } = response;
                  this.statics = data;
                });
      },
      getArticleOrderList(page = 1, options = {}){
        this.articleOrderList(this.aid, page, options)
          .then(response=>{
            let { data, meta } = response.data;
            this.orders = data;
            this.ordersMeta = meta;
          })
      },
      onChangePage(page){
        let { start_time, end_time } = this;
        this.getArticleOrderList(page, {start_time, end_time});
      }
    }
  }
</script>

<style>
  .kt-widget__head .kt-widget__info {
    overflow: hidden;
  }

  .kt-widget__section {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    height: 74px;
  }

  .kt-widget__data {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .kt-widget__username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: justify;
    display: block;
    width: 100%;
  }
</style>
