import {
  CREATE_ARTICLE,
  FIND_ARTICLE, GET_ARTICLES_COMMENTS, GET_ARTICLES_FOR_ALL, GET_ARTICLES_LIST,
  SET_ARTICLE_ERROR, SWITCH_ARTICLE_STATE,
  UPDATE_ARTICLE
} from "../store/types";
import handleReject from "../common/handleReject";
import ApiService from "../common/api.service";

export default {
  data(){
    return {
      formBusy: false,
      articlesForStore: [],
      articlesForStoreMeta: {}
    }
  },
  methods: {
    deleteArticle(id){
      this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete(`/manager/articles/${id}`)
        .then(response=>{
          this.hideBusy();
          resolve(response);
        })
        .catch(response=>{
          this.hideBusy();
          handleReject(this.$store, response, SET_ARTICLE_ERROR);
          reject(response);
        })
      })
    },
    createArticle(formData){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(CREATE_ARTICLE, formData)
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    updateArticle(formData){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(UPDATE_ARTICLE, formData)
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    findArticle(id, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(FIND_ARTICLE, id)
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    },
    getArticles(options = {page: 1, title: ''}, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_ARTICLES_LIST, options)
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    },
    switchArticleState(id, params){
      this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(SWITCH_ARTICLE_STATE, {id, params})
        .then(response=>{
          this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          this.hideBusy();
          reject(response);
        })
      })
    },
    getArticlesForStore(title = false, page = 1, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post('/manager/articles/list-for-store', {title, page})
        .then(response=>{
          let { data, meta } = response.data;
          this.articlesForStore = data;
          this.articlesForStoreMeta = meta;
          this.$store.commit(SET_ARTICLE_ERROR, null);
          resolve(response);
          busy && this.hideBusy();
        })
        .catch(response=>{
          handleReject(this.$store, response, SET_ARTICLE_ERROR);
          reject(response);
          busy && this.hideBusy();
        })
      })
    },
    getArticlesForAll(busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_ARTICLES_FOR_ALL)
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    },
    getArticlesComments(page = 1, filter = 0, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        this.$store.dispatch(GET_ARTICLES_COMMENTS, {page, filter})
        .then(response=>{
          busy && this.hideBusy();
          resolve(response)
        })
        .catch(response=>{
          busy && this.hideBusy();
          reject(response);
        })
      })
    },
    approveArticleComment(id, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post(`/manager/articles/comments/${id}/approve`)
        .then((response)=>{
          this.$store.commit(SET_ARTICLE_ERROR, null);
          resolve(response);
          busy && this.hideBusy();
        })
        .catch(response=>{
          handleReject(this.$store, response, SET_ARTICLE_ERROR);
          reject(response);
          busy && this.hideBusy();
        })
      })
    },
    deleteArticlesComment(id, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.delete(`/manager/articles/comments/${id}`)
        .then((response)=>{
          this.$store.commit(SET_ARTICLE_ERROR, null);
          resolve(response);
          busy && this.hideBusy();
        })
        .catch(response=>{
          handleReject(this.$store, response, SET_ARTICLE_ERROR);
          reject(response);
          busy && this.hideBusy();
        })
      })
    },
    articleStatics(id, {start_time = '', end_time = ''}, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(`/manager/articles/${id}/order-statics`, `?start_time=${start_time}&end_time=${end_time}`)
        .then((response)=>{
          this.$store.commit(SET_ARTICLE_ERROR, null);
          resolve(response);
          busy && this.hideBusy();
        })
        .catch(response=>{
          handleReject(this.$store, response, SET_ARTICLE_ERROR);
          reject(response);
          busy && this.hideBusy();
        })
      })
    },
    articleOrderList(id, page = 1, {start_time = '', end_time = ''}, busy = true){
      busy && this.showBusy();
      return new Promise((resolve, reject) => {
        ApiService.setHeader();
        ApiService.get(`/manager/articles/${id}/order-list`, `?start_time=${start_time}&end_time=${end_time}&page=${page}`)
        .then((response)=>{
          this.$store.commit(SET_ARTICLE_ERROR, null);
          resolve(response);
          busy && this.hideBusy();
        })
        .catch(response=>{
          handleReject(this.$store, response, SET_ARTICLE_ERROR);
          reject(response);
          busy && this.hideBusy();
        })
      })
    },
  }
}
